<template>
  <!-- eslint-disable -->
  <div>
    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <b-card no-body>
        <validation-observer #default="{ invalid }" ref="refFormObserver">
          <b-form class="p-1">
            <b-row class="mb-1">
              <b-col md="6">
                <h5>Archivos Adjuntos</h5>
              </b-col>
              <b-col md="6" class="d-flex justify-content-md-end justify-content-center">
                <b-button v-if="edit" @click="saveFile" :variant="'success'" class="">
                  Guardar
                </b-button>
                <b-button v-else @click="saveTemp" :variant="'success'" class="">
                  Guardar
                </b-button>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col md="6">
                <validation-provider #default="{ errors }" name="description" rules="requeridoE">
                  <b-form-group label="Descripción" label-for="description">
                    <b-form-input
                      v-model="items.description"
                      id="description"
                      placeholder="Descripción"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" class="">
                <validation-provider #default="{ errors }" name="file" rules="requeridoE">
                  <b-form-group label="Archivo" label-for="file">
                    <b-form-file
                      v-model="items.file"
                      placeholder="Seleccione un archivo o arrastre aqui..."
                      drop-placeholder="Arrastre aqui..."
                      id="file"
                    />
                    <small class="text-warning">Maximo tamaño de archivo 20mb</small>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        <b-row>
          <b-col md="12">
            <b-table
              class="position-relative"
              :hover="true"
              :items="records"
              responsive
              :fields="fields"
              show-empty
              empty-text="No existen"
              @sort-changed="sortChanged"
              no-border-collapse
            >
              <!-- Column: Actions -->
              <!-- <template v-slot:cell(nro)="{ item, field: { key, index } }">
                <span>{{index}}</span>
              </template> -->
              <template #cell(created_at)="data">
                <span>{{ moment(data.item.created_at).format('yyyy-MM-DD') }}</span>
              </template>
              <template #cell(actions)="data">
                <div class="d-flex align-items-center">
                  <b-media v-if="edit" vertical-align="center" class="mr-1">
                    <a v-if="data.item.url != ''" target="_blank" :href="api_url + data.item.url">
                      <feather-icon icon="FileTextIcon" />
                    </a>
                  </b-media>
                  <b-button
                    v-if="edit"
                    size="sm"
                    class=""
                    v-b-tooltip.noninteractive.hover.top="'Eliminar'"
                    variant="flat-danger"
                    @click.prevent="deleteAction(data.item)"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                  <b-button
                    v-else
                    size="sm"
                    class=""
                    v-b-tooltip.noninteractive.hover.top="'Eliminar'"
                    variant="flat-danger"
                    @click.prevent="deleteTemp(data.item)"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </div>
              </template>
            </b-table>
            <div class="mx-2 mb-2">
              <b-row>
                <b-col sm="3">
                  <b-form-group
                    label-cols="4"
                    label-cols-md="4"
                    label-size="md"
                    label="Entradas"
                    label-for="input-md"
                  >
                    <b-form-select
                      v-model="showEntrie"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="entries"
                      @change="changeSizePage"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="3" class="mt-75" style="font-size: 12px">
                  <span> {{ totalElements }} Registros en total</span>
                </b-col>
                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalElements"
                    :per-page="showEntrie"
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                    @change="cambioPagina"
                    pills
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="15" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="15" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import FilesService from '@/services/FilesService'
import vSelect from 'vue-select'
import moment from 'moment'
const APIURL = process.env.APIURLFILE
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
export default {
  /* props: ['edit', 'itemId'], */
  props: {
    edit: {
      type: Boolean,
      default: false
    },
    itemId: {
      type: Number,
      default: null
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect
  },
  data() {
    return {
      api_url: APIURL,
      showLoading: false,
      tabIndex: 0,
      records: [],
      filesTemp: [],
      fields: [
        { key: 'id', label: 'Nro', sortable: false },
        { key: 'description', label: 'Descripción', sortable: false },
        { key: 'created_at', label: 'Fecha Actualización', sortable: false },
        { key: 'file_name', label: 'Nombre Archivo', sortable: false },
        { key: 'actions', label: 'Acciones' }
      ],
      items: {
        description: '',
        file: null,
        restriction_id: this.$route.params.id
      },
      currentPage: 1,
      entries: [10, 20, 50, 100],
      showEntrie: 10,
      totalElements: 0,
      id: 0,
      sort: 'id',
      order: 'desc'
    }
  },
  mounted() {
    if (this.edit) {
      this.getData()
    }
  },
  methods: {
    cambioPagina(e) {
      this.currentPage = e
      if (this.edit) {
        this.getData()
      } else{
        this.totalElements = this.records.length
      }
      
    },
    changeSizePage() {
      if (this.edit) {
        this.getData()
      } else{
        this.totalElements = this.records.length
      }
    },
    sortChanged(data) {
      this.sort = data.sortBy
      if (data.sortDesc) {
        this.order = 'desc'
      } else this.order = 'asc'
    },
    resetForm() {
      this.$refs.refFormObserver.reset()
      this.items.description = ''
      this.items.file = null
    },
    async getData() {
      this.showLoading = true
      console.log(this.$route.params.id, 'this.$route.params.id')
      if(this.$route.params.id == undefined){
        this.$route.params.id = this.itemId
      }
      let url =
        `?filter=` +
        '[{"keyContains":"restriction_id","key":"equals","value":' +
        this.$route.params.id +
        '}]'
      const resp = await FilesService.getFiles(url, this.$store)

      if (resp.status) {
        this.records = resp.data.rowsWithRowNumber
        this.totalElements = resp.data.responseFilter.total_rows
      }
      this.showLoading = false
    },
    async saveFile() {
      this.showLoading = true
      let datos = new FormData()
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          datos.append('description', this.items.description)
          datos.append('file', this.items.file)
          datos.append('restriction_id', this.items.restriction_id)
          const resp = await FilesService.saveFile(datos, this.$store)
          console.log('resp', resp)
          if (resp.status) {
            this.$swal({
              title: 'Registrado',
              text: 'El archivo ha sido registrado con éxito.',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
            this.resetForm()
            this.getData()
          } else {
            this.$swal({
              title: 'Error!',
              text: ' Hubo un error al registrar el archivo',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
          }
        }
      })
      this.showLoading = false
      // console.log('items', this.items)
    },
    saveTemp() {
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          console.log('this.items', this.items)
          this.records.push({
            id: this.records.length + 1,
            description: this.items.description,
            file: this.items.file,
            created_at: new Date(),
            file_name: this.items.file.name
          })
          this.resetForm()
          this.totalElements = this.records.length
        }
      })
    },
    async deleteAction(data) {
      this.$swal({
        title: '¿Desea eliminar este archivo?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminalo',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.deleteData(data.id)
        }
      })
    },
    async deleteData(id) {
      console.log('id', id)
      const resp = await FilesService.deleteFile(id, this.$store)
      if (resp.status) {
        this.currentPage = 1
        this.$swal({
          icon: 'success',
          title: 'Eliminado!',
          text: 'El archivo ha sido eliminado.',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
        this.getData()
      } else {
        this.$swal({
          icon: 'error',
          title: 'Error',
          text: 'Ocurrió un error al eliminar el archivo seleccionado.',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
      }
    },
    deleteTemp(data) {
      console.log('data', data)
      this.$swal({
        title: '¿Desea eliminar este archivo?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminalo',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.deleteDataTemp(data.id)
        }
      })
    },
    deleteDataTemp(id) {
      console.log('id', id)
      this.records = this.records.filter((e) => e.id != id)
      this.currentPage = 1
      this.totalElements = this.records.length
      this.$swal({
        icon: 'success',
        title: 'Eliminado!',
        text: 'El archivo ha sido eliminado.',
        customClass: {
          confirmButton: 'btn btn-success'
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
