<template>
  <!-- eslint-disable -->
  <b-sidebar
    id="add-prov"
    :visible="isAdd"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    no-close-on-backdrop
    @change="(val) => $emit('update:is-add', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ isEdit ? 'Editar' : 'Agregar nueva' }} restriccion provisional</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click=";[hide(), resetForm()]"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ invalid }" ref="refFormObserver">
        <!-- Form -->

        <b-form class="p-2" @submit.prevent="onSubmit(items)">
          <validation-provider #default="{ errors }" name="description" rules="requeridoE">
            <b-form-group label="Descripción" label-for="description">
              <b-form-textarea
                id="description"
                placeholder="Descripción"
                rows="3"
                max-rows="3"
                v-model="items.description"
                no-resize
              ></b-form-textarea>
              <small
                class="text-danger alert"
                :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }"
                >{{ errors[0] }}</small
              >
            </b-form-group>
          </validation-provider>
          <validation-provider #default="{ errors }" name="priority" rules="requeridoE">
            <b-form-group label="Prioridad" label-for="priority">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="prioridades"
                label="name"
                input-id="priority"
                :reduce="(records) => records.name"
                v-model="items.priority"
                placeholder="Prioridad"
              />
              <small
                class="text-danger alert"
                :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }"
                >{{ errors[0] }}</small
              >
            </b-form-group>
          </validation-provider>
          <validation-provider #default="{ errors }" name="category" rules="">
            <b-form-group label="Categoria" label-for="category">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="categorias"
                label="code"
                input-id="category"
                :reduce="(categorias) => categorias.id"
                v-model="items.category_id"
                @input="getLeadTime(items.category_id)"
                placeholder="Categoria"
              >
                <template v-slot:selected-option="option">
                  {{ option.code }} - {{ option.description }}
                </template>
                <template slot="option" slot-scope="option">
                  {{ option.code }} - {{ option.description }}
                </template>
              </v-select>
              <small
                class="text-danger alert"
                :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }"
                >{{ errors[0] }}</small
              >
            </b-form-group>
          </validation-provider>
          <validation-provider #default="{ errors }" name="type" rules="">
            <b-form-group label="Tipo" label-for="type">
              <!-- <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="types"
                label="text"
                input-id="type"
                :reduce="(types) => types.value"
                v-model="items.type"
                placeholder="Tipo"
              /> -->

              <div class="d-flex w-50 align-items-center mt-1 ml-1">
                <span class="mr-2">Campo</span>
                <b-form-checkbox v-model="items.type" name="type" switch >                       
                </b-form-checkbox>                       
                  <span class="ml-2">Crítico</span>
              </div>
              <small
                class="text-danger alert"
                :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }"
                >{{ errors[0] }}</small
              >
            </b-form-group>
          </validation-provider>

          <validation-provider #default="{ errors }" name="leadTime" rules="requeridoE">
            <b-form-group label="Lead Time" label-for="leadTime">
              <b-form-input
                type="number"
                id="leadTime"
                placeholder="Lead Time"
                v-model="items.leadtime"
                no-resize
                disabled
              ></b-form-input>
              <small
                class="text-danger alert"
                :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }"
                >{{ errors[0] }}</small
              >
            </b-form-group>
          </validation-provider>
          <validation-provider #default="{ errors }" name="t-antici" rules="requeridoE|mayor">
                  <b-form-group label="T. Anticipación (Dias)" label-for="t-antici">
                    <b-form-input
                      readonly
                      v-model="items.time_anticipation"
                      id="t-antici"
                      placeholder="T. Anticipación"
                      disabled
                    />
                    <small class="text-danger" v-if="parseInt(items.time_anticipation) < items.leadtime">T. Anticipación es menor a Lead Time</small>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>

          <validation-provider #default="{ errors }" name="date_iden" rules="requeridoE">
            <b-form-group label="Fecha Identificada" label-for="date_iden">
              <flat-pickr
                @input="diferenDate(items.date_identified, 1)"
                id="date_iden"
                v-model="items.date_identified"
                class="form-control"
                :config="config"
                disabled
              />
              <small
                class="text-danger alert"
                :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }"
                >{{ errors[0] }}</small
              >
            </b-form-group>
          </validation-provider>
          <validation-provider #default="{ errors }" name="date_req" rules="requeridoE">
            <b-form-group label="Fecha Requerida" label-for="date_req">
              <flat-pickr
                @input="diferenDate(items.date_required, 2)"
                id="date_req"
                v-model="items.date_required"
                class="form-control"
                :config="config"
              />
              <small
                class="text-danger alert"
                :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }"
                >{{ errors[0] }}</small
              >
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2 justify-content-end">
            <b-button
              :disabled="invalid"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <b-spinner v-if="isDisabled" small />
              <span v-if="isDisabled" class="px-1">guardando...</span>
              <span v-else>{{ isEdit ? 'Actualizar' : 'Agregar' }}</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click=";[hide(), resetForm()]"
            >
              Cancel
            </b-button>
          </div>
          {{tiempoAnti}}
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable*/
import Vue from 'vue'
import { BootstrapVue } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import CategoryService from '@/services/CategoryService'
import RestrictionsService from '@/services/RestrictionsService'
import flatPickr from 'vue-flatpickr-component'
import ShortcutButtonsPlugin from 'shortcut-buttons-flatpickr'
import moment from 'moment'
Vue.use(BootstrapVue)
export default {
  directives: {
    Ripple
  },
  components: {
    // Form Validation
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    vSelect
  },
  props: {
    isAdd: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      required,
      // speciality: {
      //   title: ''
      // },
      isEdit: false,
      isDisabled: false,
      showLoading: false,
      tabIndex: 0,
      records: [],
      prioridades: [
        {
          id: 1,
          name: 'Alta'
        },
        {
          id: 2,
          name: 'Media'
        },
        {
          id: 3,
          name: 'Baja'
        }
      ],
      types:[
        {
          text: 'Campo',
          value: 'campo'
        },
        {
          text: 'Critico',
          value: 'critica'
        },
      ],
      config: {
        plugins: [
          ShortcutButtonsPlugin({
            theme: 'dark',
            button: [{ label: 'Hoy' }],
            onClick(index, fp) {
              let date = index ? new Date(Date.now() + 24 * index * 60 * 60 * 1000) : new Date()
              fp.setDate(date)
              fp.close()
            }
          })
        ],
        locale: {
          firstDayOfWeek: 1,
          weekdays: {
            shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
            longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
          },
          months: {
            shorthand: [
              'Ene',
              'Feb',
              'Mar',
              'Abr',
              'May',
              'Jun',
              'Jul',
              'Ago',
              'Sep',
              'Оct',
              'Nov',
              'Dic'
            ],
            longhand: [
              'Enero',
              'Febrero',
              'Мarzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre'
            ]
          }
        }
      },
      categorias: [],
      subcategorias: [],
      subcategory: [],
      especialidades: [],
      leadTime: '',
      project_id: this.$parent.$parent.project_id,
      items: {
        code: '',
        description: '',
        detail: '',
        dependency: 'Interna',
        leadtime: '',
        leadtime_modified: false,
        leadtime_comment: '',
        priority: '',
        follow_up_type: '',
        status: 'PendientePorOficializar',
        type: false,
        date_identified: new Date(),
        date_required: new Date(),
        date_commited: null,
        date_released: null,
        time_anticipation: null,
        project_id: this.$parent.$parent.project_id,
        speciality_id: null,
        activity_id: null,
        location_1_id: null,
        location_2_id: null,
        location_3_id: null,
        location_4_id: null,
        category_id: null,
        sub_category_id: null,
        owner_id: null,
        co_owner_id: null,
        user_id: null
      },
      prov_id: null,
      temp: {},
      userData: JSON.parse(localStorage.getItem('userData'))
    }
  },
  computed: {
    tiempoAnti() {
      console.log('tiempoAnti')
      if (this.items.date_identified != null && this.items.date_required != null) {
        let date1T = moment(this.items.date_identified).format('yyyy-MM-DD')
        let date2T = moment(this.items.date_required).format('yyyy-MM-DD')
        let date1 = moment(date1T)
        let date2 = moment(date2T)
        let aux_time_anticipation = date2.diff(date1, 'days') < 0 ? 0 : date2.diff(date1, 'days')
        this.items.time_anticipation = aux_time_anticipation.toString()
      }
    }
  },
  // mounted() {
  //   this.getData()
  // },
  methods: {
    validDate(fecha) {
     
      if (fecha != null) {
        const year = new Date(fecha).getFullYear()
        if (year <= 1970) {
          return ''
        }
        // console.log('data fecha', fecha)
        // console.log('fecha local', moment(fecha, 'yyyy-MM-DD HH:mm').utc(fecha).format('yyyy-MM-DD'))
        return moment(fecha, 'yyyy-MM-DD HH:mm').utc(fecha).format('yyyy-MM-DD')
      }
      return ''
    },
    getLeadTime(category_id){
      this.categorias.forEach(element => {
        if(element.id == category_id){
          this.items.leadtime = element.leadtime
        }
      });
    },
    diferenDate(fecha, val) {
      if (fecha != null) {
        if (val == 1) {
          let date1T = moment(fecha).format('yyyy-MM-DD')
          let date2T = moment(this.items.date_required).format('yyyy-MM-DD')
          let date1 = moment(date1T)
          let date2 = moment(date2T)
          if (date1.diff(date2, 'days') > 0) {
            this.$swal({
              icon: 'warning',
              title: 'Fecha inválida',
              text: 'La fecha identificada no puede ser mayor a la fecha requerida',
              customClass: {
                confirmButton: 'btn btn-success'
              }
            })
            this.items.date_identified = new Date()
          }
        } else {
          let date1T = moment(this.items.date_identified).format('yyyy-MM-DD')
          let date2T = moment(fecha).format('yyyy-MM-DD')
          let date1 = moment(date1T)
          let date2 = moment(date2T)
          if (date2.diff(date1, 'days') < 0) {
            this.$swal({
              icon: 'warning',
              title: 'Fecha inválida',
              text: 'La fecha requerida no puede ser menor a la fecha identificada',
              customClass: {
                confirmButton: 'btn btn-success'
              }
            })
            this.items.date_required = new Date()
          }
        }
      }
    },
    async getData(id) {
      this.showLoading = true
      let url =
        `?limit=10000&filter=` + JSON.stringify([{ keyContains: 'project_id', key: 'equals', value: id }])
      const respCategory = await CategoryService.getCategorys(url, this.$store)
      console.log('respCategory', respCategory)
      if (respCategory.status) {
        this.categorias = respCategory.data.rows
      }
      this.showLoading = false
    },
    setData(items) {
      if (Object.entries(items).length !== 0) {
        console.log('items add-edit', items)
        this.temp = items
        this.prov_id = items.id
        this.items.code = items.code
        this.items.description = items.description
        this.items.priority = items.priority
        this.items.category_id = items.category_id
        this.items.date_identified = this.validDate(items.date_identified)
        this.items.date_required = this.validDate(items.date_required)
        this.items.project_id = items.project_id
        this.items.leadtime = items.leadtime
        /* this.items.type = items.type */
        if (items.type === 'campo') {
          this.items.type = false;
        } else {
          this.items.type = true;
        }
        this.isEdit = true
      } else {
        this.temp = {}
        this.items = {
          code: '',
          description: '',
          detail: '',
          dependency: 'Interna',
          leadtime: '',
          leadtime_modified: false,
          leadtime_comment: '',
          priority: '',
          follow_up_type: '',
          status: 'PendientePorOficializar',
          type: false,
          date_identified: new Date(),
          date_required: new Date(),
          date_commited: null,
          date_released: null,
          time_anticipation: null,
          project_id: this.$parent.$parent.project_id,
          speciality_id: null,
          activity_id: null,
          location_1_id: null,
          location_2_id: null,
          location_3_id: null,
          location_4_id: null,
          category_id: null,
          sub_category_id: null,
          owner_id: null,
          co_owner_id: null,
          user_id: null
        }
        this.isEdit = false
      }
      console.log('temp EN ADD', this.temp)
    },
    resetForm() {
      this.$refs.refFormObserver.reset()
      this.isEdit = false
      this.items = {
        project_id: null,
        description: '',
        priority: '',
        category_id: '',
        date_required: null,
        date_identified: null,
        leadtime: '',
        time_anticipation: null,
      }
    },
    async onSubmit(data) {
      console.log('data', data)
      this.$refs.refFormObserver.validate().then(async (success) => {
        this.showLoading = true
        console.log('data TO SAVE', data)
        if (success) {
          data.user_id = this.userData.id
          let resp = ''
          if (data.type) {
            data.type = "critica"
          } else {
            data.type = "campo"
          }
          console.log('data TO SAVE', data)

          if (this.isEdit == false) {
            resp = await RestrictionsService.saveRestricciones(data, this.$store)
          } else {
            let id = this.prov_id
            resp = await RestrictionsService.updateRestricciones(id, data, this.$store)
            console.log("UPDATEADO")
          }
          console.log('resp', resp)
          if (resp.status) {
            this.$swal({
              title: this.isEdit == true ? 'Actualizado' : 'Registrado',
              text: 'Los datos han sido ' + (this.isEdit == true ? 'actualizado.' : 'registrado.'),
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
            this.$parent.$parent.getData()
            this.$emit('update:is-add', false)
            this.resetForm()
          } else {
            this.$swal({
              title: 'Error!',
              text: resp.data.message
                ? resp.data.message
                : 'Ocurrió un error al ' +
                  (this.isEdit == true ? 'actualizar' : 'registrar') +
                  ' los datos del formulario.',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
          }
        }
        this.showLoading = false
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
