var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-prov","visible":_vm.isAdd,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":"","no-close-on-backdrop":""},on:{"change":function (val) { return _vm.$emit('update:is-add', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.isEdit ? 'Editar' : 'Agregar nueva')+" restriccion provisional")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":function($event){;[hide(), _vm.resetForm()]}}})],1),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit(_vm.items)}}},[_c('validation-provider',{attrs:{"name":"description","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Descripción","label-for":"description"}},[_c('b-form-textarea',{attrs:{"id":"description","placeholder":"Descripción","rows":"3","max-rows":"3","no-resize":""},model:{value:(_vm.items.description),callback:function ($$v) {_vm.$set(_vm.items, "description", $$v)},expression:"items.description"}}),_c('small',{staticClass:"text-danger alert",style:({ height: (errors.length > 0 ? 20 : 0) + 'px' })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"priority","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Prioridad","label-for":"priority"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.prioridades,"label":"name","input-id":"priority","reduce":function (records) { return records.name; },"placeholder":"Prioridad"},model:{value:(_vm.items.priority),callback:function ($$v) {_vm.$set(_vm.items, "priority", $$v)},expression:"items.priority"}}),_c('small',{staticClass:"text-danger alert",style:({ height: (errors.length > 0 ? 20 : 0) + 'px' })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"category","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Categoria","label-for":"category"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.categorias,"label":"code","input-id":"category","reduce":function (categorias) { return categorias.id; },"placeholder":"Categoria"},on:{"input":function($event){return _vm.getLeadTime(_vm.items.category_id)}},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}}],null,true),model:{value:(_vm.items.category_id),callback:function ($$v) {_vm.$set(_vm.items, "category_id", $$v)},expression:"items.category_id"}}),_c('small',{staticClass:"text-danger alert",style:({ height: (errors.length > 0 ? 20 : 0) + 'px' })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"type","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tipo","label-for":"type"}},[_c('div',{staticClass:"d-flex w-50 align-items-center mt-1 ml-1"},[_c('span',{staticClass:"mr-2"},[_vm._v("Campo")]),_c('b-form-checkbox',{attrs:{"name":"type","switch":""},model:{value:(_vm.items.type),callback:function ($$v) {_vm.$set(_vm.items, "type", $$v)},expression:"items.type"}}),_c('span',{staticClass:"ml-2"},[_vm._v("Crítico")])],1),_c('small',{staticClass:"text-danger alert",style:({ height: (errors.length > 0 ? 20 : 0) + 'px' })},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"leadTime","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Lead Time","label-for":"leadTime"}},[_c('b-form-input',{attrs:{"type":"number","id":"leadTime","placeholder":"Lead Time","no-resize":"","disabled":""},model:{value:(_vm.items.leadtime),callback:function ($$v) {_vm.$set(_vm.items, "leadtime", $$v)},expression:"items.leadtime"}}),_c('small',{staticClass:"text-danger alert",style:({ height: (errors.length > 0 ? 20 : 0) + 'px' })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"t-antici","rules":"requeridoE|mayor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"T. Anticipación (Dias)","label-for":"t-antici"}},[_c('b-form-input',{attrs:{"readonly":"","id":"t-antici","placeholder":"T. Anticipación","disabled":""},model:{value:(_vm.items.time_anticipation),callback:function ($$v) {_vm.$set(_vm.items, "time_anticipation", $$v)},expression:"items.time_anticipation"}}),(parseInt(_vm.items.time_anticipation) < _vm.items.leadtime)?_c('small',{staticClass:"text-danger"},[_vm._v("T. Anticipación es menor a Lead Time")]):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"date_iden","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Fecha Identificada","label-for":"date_iden"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"date_iden","config":_vm.config,"disabled":""},on:{"input":function($event){return _vm.diferenDate(_vm.items.date_identified, 1)}},model:{value:(_vm.items.date_identified),callback:function ($$v) {_vm.$set(_vm.items, "date_identified", $$v)},expression:"items.date_identified"}}),_c('small',{staticClass:"text-danger alert",style:({ height: (errors.length > 0 ? 20 : 0) + 'px' })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"date_req","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Fecha Requerida","label-for":"date_req"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"date_req","config":_vm.config},on:{"input":function($event){return _vm.diferenDate(_vm.items.date_required, 2)}},model:{value:(_vm.items.date_required),callback:function ($$v) {_vm.$set(_vm.items, "date_required", $$v)},expression:"items.date_required"}}),_c('small',{staticClass:"text-danger alert",style:({ height: (errors.length > 0 ? 20 : 0) + 'px' })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2 justify-content-end"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"disabled":invalid,"variant":"primary","type":"submit"}},[(_vm.isDisabled)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),(_vm.isDisabled)?_c('span',{staticClass:"px-1"},[_vm._v("guardando...")]):_c('span',[_vm._v(_vm._s(_vm.isEdit ? 'Actualizar' : 'Agregar'))])],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":function($event){;[hide(), _vm.resetForm()]}}},[_vm._v(" Cancel ")])],1),_vm._v(" "+_vm._s(_vm.tiempoAnti)+" ")],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }